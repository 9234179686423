import store from '@/store'

export const logSlideProductAdded = async (productAdded) => {
  if (!productAdded) return

  try {
    const jurisdiction = getJurisdictionValue(productAdded)
    // Only pass in product fields that were passed in
    const interaction = {
      type: 'button',
      name: 'product-added',
      product: {
        ...(productAdded?.id !== undefined && { id: productAdded.id }),
        ...(productAdded?.name !== undefined && { name: productAdded.name }),
        ...(jurisdiction !== undefined && { jurisdiction: jurisdiction }),
        ...(productAdded?.parentItemId !== undefined && { parentItemId: productAdded.parentItemId }),
      },
    }

    await store.dispatch('stageline/logSlideInteraction', interaction, { root: true })
  } catch (ex) {
    // Just Suppressing the error
  }
}

const getJurisdictionValue = (productAdded) => {
  if (productAdded?.jurisdiction) {
    return productAdded?.jurisdiction?.state_province_region || productAdded?.jurisdiction
  }
  return undefined
}

// Initialize Stageline Methods
export function setStagelineJurisdiction() {
  store.dispatch('stageline/setJurisdiction', store.getters['companies/domesticJurisdiction'])
}
